import { GatsbyImage } from 'gatsby-plugin-image'
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SectionContainer from '../../components/molecules/SectionContainer'
import WorkWithUs from '../../components/molecules/WorkWithUs'
import Seo from '../../helpers/seo'
import useStaticImage from '../../hooks/useStaticImage'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import Layout from '../../templates/layout'
import hiringStepsInfo from './hiringSteps.json'
import testimonialInformation from './testimonialInformation.json'

import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Filter } from '../../components/atoms/Filter'
import Banner from '../../components/molecules/Banner'
import Button from '../../components/molecules/Button'
import JobsPagination from '../../components/molecules/JobsPagination'
import Accordion from '../../components/organisms/Accordion'
import Benefits from '../../components/organisms/Benefits'
import useContentfulFAQ from '../../hooks/useContentfulFAQ'
import useContentfulJobs from '../../hooks/useContentfulJobs'
import './styles.scss'

const WhyWorkAtDigital = () => {
  return (
    <SectionContainer>
      <div className="why-dr">
        <h2 className="why-dr__title">
          Por que trabalhar na <span>DR_</span>?
        </h2>
        <p>
          Na DR_ as pessoas são nosso maior ativo. Valorizamos o talento e o esforço
          individual, acreditamos no poder da diversidade e criamos um ambiente onde cada
          colaborador pode ser autêntico e contribuir de forma significativa. Aqui, você
          será parte de uma equipe que se apoia, onde suas ideias são ouvidas e você tem
          espaço para crescer, tanto como profissional quanto como pessoa.
        </p>
        <p>
          Trabalhamos em projetos desafiadores que exigem soluções criativas e inovadoras.
          Desde o desenvolvimento de tecnologias avançadas até a criação de novos
          produtos, você terá autonomia para tomar decisões e explorar suas ideias.
          Acreditamos que grandes resultados surgem quando nossos profissionais são livres
          para experimentar, aprender e crescer. Seu trabalho será reconhecido e você verá
          o impacto real de suas contribuições.
        </p>
        <p>
          A DR_ se destaca por sua presença internacional, projetos de alto nível e um
          ambiente que promove o crescimento contínuo. Se você quer estar em um lugar onde
          a inovação tecnológica e o potencial humano andam de mãos dadas, a DR_ é o seu
          destino.
        </p>
      </div>
    </SectionContainer>
  )
}

const HiringSteps = () => {
  return (
    <SectionContainer wrapperClassName="hiring-bg">
      <h3>Nosso processo seletivo</h3>
      <ol className="hiring-steps">
        {hiringStepsInfo.map(({ title, description }, i) => {
          return (
            <li key={title} className="hiring-steps__item">
              <h3 className="hiring-steps__title">{`${i + 1}. ${title}`}</h3>
              <p className="hiring-steps__description">{description}</p>
            </li>
          )
        })}
      </ol>
    </SectionContainer>
  )
}

const DigitalTeamReport = () => {
  const [getImage] = useStaticImage()
  const [swiperRef, setSwiperRef] = useState()
  const [windowWidth, _] = useWindowDimensions()

  const handleLeftSwipe = useCallback(() => {
    swiperRef.slidePrev()
  }, [swiperRef])
  const handleRightSwipe = useCallback(() => {
    swiperRef.slideNext()
  }, [swiperRef])

  const Buttons = () => {
    return (
      <div className="digital-team__buttons-container">
        <button
          aria-label="prev"
          type="button"
          onClick={handleLeftSwipe}
          className="swiper-button-prev"
        >
          &nbsp;
        </button>

        <button
          aria-label="next"
          type="button"
          onClick={handleRightSwipe}
          className="swiper-button-next"
        >
          &nbsp;
        </button>
      </div>
    )
  }

  return (
    <SectionContainer className="digital-team">
      <div className="digital-team__title__container">
        <h2 className="digital-team__title">Veja o que nosso time fala sobre a DR_</h2>
        {windowWidth >= 1024 && <Buttons />}
      </div>
      <Swiper
        onSwiper={setSwiperRef}
        spaceBetween={4}
        loop
        autoHeight
        className="digital-team__swiper-container"
        modules={[EffectFade]}
      >
        {testimonialInformation.map(({ image, text, name, role }) => {
          return (
            <SwiperSlide key={`${name}-${role}`}>
              <div className="digital-team__card">
                <div>
                  <GatsbyImage
                    loading="lazy"
                    quality={95}
                    formats={['auto', 'webp', 'avif']}
                    alt={name}
                    image={getImage(image)}
                    className="digital-team__card__image"
                  />
                </div>
                <div className="digital-team__card__info-container">
                  <p className="digital-team__card__paragraph">{text}</p>
                  <p className="digital-team__card__person-info">
                    <b>{name} |</b> {role}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      {windowWidth < 1024 && <Buttons />}
    </SectionContainer>
  )
}

const Jobs = forwardRef((_, ref) => {
  const jobs = useContentfulJobs()
  const [allJobs, setAllJobs] = useState([])
  const [filters, setFilters] = useState({
    sector: '',
    vacancyType: '',
    operatingModel: '',
  })
  const [jobsPerPage, setJobsPerPage] = useState(5)
  const [activePage, setActivePage] = useState(0)
  const [loading, setLoading] = useState(true)

  const jobApplicationLink = process.env.GATSBY_JOB_APPLICATION_LINK

  const handleFilterValueChange = (event) => {
    const { name, value } = event.target

    setFilters({
      ...filters,
      [name]: value,
    })
  }

  const jobsFilter = (jobs, filters) => {
    return jobs.filter((job) => {
      const matchesSector = !filters.sector || job.sector === filters.sector
      const matchesVacancyType =
        !filters.vacancyType || job.vacancyType === filters.vacancyType
      const matchesOperatingModel =
        !filters.operatingModel || job.operatingModel === filters.operatingModel
      return matchesSector && matchesVacancyType && matchesOperatingModel
    })
  }

  const openPositions = allJobs.length
  const shouldDisplayFilters = allJobs.length > 5

  const handleJobsPerPageChange = (event) => {
    setJobsPerPage(parseInt(event.target.value))
  }

  const workModel = jobs.map((job) => job.operatingModel)
  const sectors = jobs.map((job) => job.sector)
  const vacancyType = jobs.map((job) => job.vacancyType)

  useEffect(() => {
    try {
      const filteredJobs = jobsFilter(jobs, filters)
      setAllJobs(filteredJobs)
    } catch (error) {
      console.error('Erro ao filtrar jobs:', error)
    } finally {
      setLoading(false)
    }
  }, [jobs, filters, setAllJobs])

  return (
    <SectionContainer>
      <article className="jobs">
        {shouldDisplayFilters && (
          <div className="jobs__filter" ref={ref}>
            <Filter
              handleFilterChange={handleFilterValueChange}
              options={['Todas as opções', ...vacancyType]}
              orientation={'vertical'}
              title={'Tipo de vaga'}
              name={'vacancyType'}
            />
            <Filter
              handleFilterChange={handleFilterValueChange}
              options={['Todas as opções', ...workModel]}
              orientation={'vertical'}
              title={'Modelo de trabalho'}
              name={'operatingModel'}
            />
            <Filter
              handleFilterChange={handleFilterValueChange}
              options={['Todas as opções', ...sectors]}
              orientation={'vertical'}
              title={'Departamento'}
              name={'sector'}
            />
          </div>
        )}

        <div className="jobs__header">
          <p className="jobs__quantity">
            {openPositions ? `Vagas abertas: ${openPositions}` : 'Nenhuma vaga aberta'}
          </p>
          <Filter
            title={'Itens por página'}
            orientation={'horizontal'}
            handleFilterChange={handleJobsPerPageChange}
            value={jobsPerPage}
            options={[5, 10, 15]}
            size={'--sm'}
          />
        </div>

        {loading ? (
          <p>Carregando vagas...</p>
        ) : openPositions > 0 ? (
          <>
            <JobsPagination
              allJobs={allJobs}
              jobsPerPage={jobsPerPage}
              activePage={activePage}
              setActivePage={setActivePage}
            />
            <div className="jobs__talent-bank">
              <p>
                Se você não encontrou uma oportunidade que se encaixe no seu perfil, não
                se desanime! Ao se inscrever em nosso Banco de Talentos, você garante que
                sua experiência e habilidades sejam vistas por nossos recrutadores.
              </p>
              <p>
                Estamos sempre em busca de talentos como o seu para futuras vagas. Com seu
                cadastro, você receberá contatos sobre novas oportunidades que se alinhem
                ao seu perfil.
              </p>
            </div>
            <Button
              text="Entrar no Banco de Talentos"
              classButton="primary"
              fontColor="orange-dark"
              link={jobApplicationLink}
            />
          </>
        ) : (
          <p className="jobs__no-vacancy">
            Não temos vagas abertas no momento, mas nosso banco de talentos está sempre
            aberto! 🙂
          </p>
        )}
      </article>
    </SectionContainer>
  )
})

const FAQ = () => {
  const questions = useContentfulFAQ()

  return (
    <SectionContainer>
      <div className="faq-container">
        <h3>
          <span>DR_ Carreiras:</span> Perguntas Frequentes
        </h3>
        <p>
          Tem interesse em fazer parte do time da DR_? Reunimos aqui respostas para as
          dúvidas mais comuns sobre como é o nosso dia a dia, nosso processo seletivo e
          dicas de como se destacar.
        </p>
        {questions.map(({ id, question, answer }) => (
          <Accordion key={id} title={question} content={answer} />
        ))}
      </div>
    </SectionContainer>
  )
}

const Careers = () => {
  const jobsRef = useRef(null)
  return (
    <Layout page="Carreiras">
      <Banner image="banner-about-us.webp" />
      <Jobs ref={jobsRef} />
      <WhyWorkAtDigital />
      <HiringSteps />
      <Benefits />
      <DigitalTeamReport />
      <FAQ />
      <WorkWithUs />
    </Layout>
  )
}

export const Head = () => <Seo title="Carreiras" />

export default Careers
